import { QueryClient, useQuery } from '@tanstack/vue-query'
import QueryKeys from '@/vue-query/QueryKeys'

import { useProjectApi } from '../api/useProjectApi'
import { FirewallPolicyDto } from '~/api/contracts/models'

export function useProjectFirewallPoliciesQuery(projectId: Ref<string>) {
    const client = useProjectApi()

    const isEnabled = computed(() => !!projectId.value)

    return useQuery(
       [QueryKeys.FirewallPolicies, projectId], 
       () => client.listFirewallPolicies(projectId.value), { refetchInterval: false, enabled: isEnabled, staleTime: 5 * 60 * 1000 })
 }
 
 export function useProjectFirewallPoliciesQueryPrefetch(
    queryClient: QueryClient,
    projectId: Ref<string>
 ): Promise<void> {
    const client = useProjectApi()
    return queryClient.prefetchQuery<FirewallPolicyDto[]>([QueryKeys.FirewallPolicies, projectId], () =>
       client.listFirewallPolicies(projectId.value)
    )
 }